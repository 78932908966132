<template>
  <component :is="type" :value="link" />
</template>
<script>
import OfficeComponent from "./Office";
import PdfComponent from "./Pdf";
export default {
  components: {
    OfficeComponent,
    PdfComponent
  },
  data() {
    return {
      type: null,
      link: "",
    };
  },
  created() {
    this.stateType();
  },
  methods: {
    stateType: function () {
      let query_type = this.$route.query.type;
      this.link = this.$route.query.link;
      if (
        query_type == "WORD" ||
        query_type == "POWER_POINT" ||
        query_type == "EXCEL"
      ) {
        this.type = "OfficeComponent";
      }
      if (
        query_type == "PDF"
      ) {
        this.type = "PdfComponent";
      }
    },
  },
};
</script>