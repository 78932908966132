<template>
  <div style="height: 100%">
    <iframe :src="value" frameborder="0" width="100%" height="100%"></iframe>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
};
</script>